import { useEffect, useState } from 'react'
import { Box, Button, Tab, Tabs } from '@mui/material'
import { useRouter } from 'next/router'
import { useQuery } from 'react-query'
import { Cancel, CheckCircle } from '@mui/icons-material'
import ROUTES from '@constant/Routes'
import Header from '@module/Header/Header'

import { getAllPhilosophicPacks } from '@api/philosophic-pack'
import useCurrentUser from '@hook/user/useCurrentUser'
import { useMTPageParams } from '@ui/MUITable/hooks/usePageParams'
import { MUITable } from '@ui/MUITable'
import { getFilters, setFilters, formatDate } from '@util/helpers'
import { getUserPref } from '@hook/common/useUserPref'
import { UserPrefKey } from '@type/usePref'
import { PublishedSwitch } from '@ui/MUITable/components/PublishFilter'
import ValueStatus from '@ui/Status'
import DialogComponent from '@commons/modules/DialogComponent'
import { openInNewTab } from '@util/openInNewTab'
import { RoleType } from '@constant/index'

const PhilosophicPackInternetApps = () => {
  const { isAdmin, sessionUser, refetch } = useCurrentUser()
  const router = useRouter()

  const [showDialog, setShowDialog] = useState(false)

  const { pagination, search, sorting } = useMTPageParams()

  const publishedFilter = getFilters('published')
  const published =
    !publishedFilter || (publishedFilter?.includes('yes') && publishedFilter?.includes('no'))
      ? undefined
      : publishedFilter?.includes('yes')

  const { data, isFetching } = useQuery(['philosophic-packs', router.query], () =>
    getAllPhilosophicPacks({
      pagination,
      search,
      sorting,
      published,
      isUGC: !isAdmin ? router.query.tab === '1' : undefined,
    }),
  )

  useEffect(() => {
    const prevState = getUserPref(UserPrefKey.PhilPackPublished) || []
    if (prevState?.length) setFilters({ filterKey: 'published', value: prevState })
    else if (router.isReady && getFilters('published') === undefined)
      setFilters({ filterKey: 'published', value: ['yes', 'no'] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      id: 'title',
      header: 'Title',
      accessorFn: (row) => row.title,
      size: 300,
    },
    {
      id: 'createdAt',
      header: 'Created At',
      accessorFn: (row) => formatDate(row.createdAt as Date),
      size: 150,
    },
    {
      id: 'published',
      header: 'Published',
      accessorFn: (row) => row.published,
      enableSorting: false,
      Cell: ({ row }) =>
        row.original.published ? <CheckCircle color="success" /> : <Cancel color="error" />,
      size: 100,
    },
  ]
  if (isAdmin) {
    const authorColumn = {
      id: 'creator',
      header: 'Author',
      accessorFn: (row) => row.creator?.username,
      size: 100,
    }
    const areaColumn = {
      id: 'area',
      header: 'Underground',
      accessorFn: (row) => row.area?.name,
      size: 100,
    }

    const sceneColumn = {
      id: 'scene',
      header: 'Scene',
      accessorFn: (row) => row.scene?.name,
      size: 100,
    }

    const orderColumn = {
      id: 'priority',
      header: 'Priority',
      accessorFn: (row) => row.priority,
      size: 100,
    }
    columns.splice(1, 0, authorColumn, areaColumn, sceneColumn, orderColumn)
  }
  if (!isAdmin && router.query.tab === '1') {
    columns.push({
      id: 'status',
      header: 'Status',
      accessorFn: (row) => row.userGeneratedContent?.status,
      enableSorting: false,
      size: 100,
      Cell: ({ row }) => {
        const status = row.original.userGeneratedContent?.status
        return status ? <ValueStatus value={status} /> : null
      },
    })
  }

  useEffect(() => {
    const handleWindowFocus = () => {
      if (!sessionUser?.roles?.includes(RoleType.CREATOR)) refetch()
    }
    window.addEventListener('focus', handleWindowFocus)

    return () => {
      window.removeEventListener('focus', handleWindowFocus)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box sx={{ my: 0 }}>
      <DialogComponent
        title="You have to be a subscribed user to continue."
        description="Proceed to payment page?"
        open={showDialog}
        handleAgree={() => {
          setShowDialog(false)
          openInNewTab(`${process.env.NEXT_PUBLIC_HERO_APP_URL}${ROUTES.Payment}`)
        }}
        handleDisagree={() => setShowDialog(false)}
      />
      <Header
        title="Philosophy Packs"
        middleComponent={
          <Button
            style={{ marginLeft: 10 }}
            variant="outlined"
            size="small"
            onClick={(e) => {
              e.stopPropagation()
              if (isAdmin) router.push(ROUTES.InternetAppsAddPhilosophicPack)
              else if (sessionUser?.roles?.includes(RoleType.CREATOR))
                router.push(ROUTES.RecommendPhilosophicPack)
              else setShowDialog(true)
            }}
          >
            {isAdmin ? '+ Add' : 'Recommend'} Philosophy pack
          </Button>
        }
      />
      {!isAdmin && (
        <Tabs
          sx={{
            '& .MuiTab-root': {
              fontWeight: 'bold',
            },
            mb: 2,
          }}
          value={+(router.query.tab || '0')}
          onChange={(_, v) =>
            router.push({ query: { ...router.query, tab: v } }, undefined, { shallow: true })
          }
        >
          <Tab label="All" />
          <Tab label="Recommended Philosophy Packs" />
        </Tabs>
      )}
      <MUITable
        loading={isFetching}
        data={data?.data || []}
        rowCount={data?.count || 0}
        height="calc(100vh - 200px)"
        enableRowNumbers
        getRowLinkPath={(row) =>
          `${process.env.NEXT_PUBLIC_RIGGED_ECONOMY_URL}${(router.query.tab === '1'
            ? ROUTES.RecommendPhilosophicPackDetails
            : ROUTES.InternetAppsPhilosophicPackDetails
          ).replace('[id]', row.id)}`
        }
        additionalToolbarButtons={<PublishedSwitch prefKey={UserPrefKey.PhilPackPublished} />}
        columns={columns}
      />
    </Box>
  )
}

export default PhilosophicPackInternetApps
